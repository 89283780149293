import React from "react";
import "./App.css";
import Helmet from "react-helmet";

const App = () => {
  return (
    <div className="App">
      <Helmet>
        <html lang="en" />

        <title>Shri Guru Ravidass Sabha Vienna, Austria</title>
        <meta name="title" content="Shri Guru Ravidass Sabha Vienna, Austria" />
        <meta
          name="description"
          content="On 24th May 2009, our dear Sant Ramanandji was brutely assassinated in the Shri Guru Ravidass Temple on the Pelzgasse in Vienna, Austria."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://viennasabha.com/" />

        <meta property="fb:pages" content="573356802808822" />

        <meta
          property="og:title"
          content="Shri Guru Ravidass Sabha Vienna, Austria"
        />
        <meta
          property="og:description"
          content="On 24th May 2009, our dear Sant Ramanandji was brutely assassinated in the Shri Guru Ravidass Temple on the Pelzgasse in Vienna, Austria."
        />
        <meta
          property="og:image"
          content="https://viennasabha.com/registered-image-ravidass-ji.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://viennasabha.com/" />
        <meta
          property="twitter:title"
          content="Shri Guru Ravidass Sabha Vienna, Austria"
        />
        <meta
          property="twitter:description"
          content="On 24th May 2009, our dear Sant Ramanandji was brutely assassinated in the Shri Guru Ravidass Temple on the Pelzgasse in Vienna, Austria."
        />
        <meta property="twitter:image" content="" />
      </Helmet>
      <div className="container">
        <h1>Jai Gurdev Dhan Gurdev</h1>
        <div className="under-construction">
          <p>Our site is under Construction</p>
        </div>
        <div className="guru-and-saints-images">
          <div className="guru-ravidass">
            <img
              className="guru-ravidass"
              src="registered-image-ravidass-ji.png"
              alt="Ravidass guru"
            />
          </div>
        </div>

        <ul className="social-media">
          <li>
            <a
              href="https://www.facebook.com/ViennaSabha/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="facebook.png" alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/viennasabha/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="instagram.png" alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/c/viennasabha"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="youtube.png" alt="" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/viennasabha"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="twitter.png" alt="" />
            </a>{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default App;
